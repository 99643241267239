import React, { useState, useEffect } from 'react';
import useHandleAddProduct from '../../../utils/handleAddProduct';
import { BASKET_CACHE_KEY } from '../../../utils/handleLocalStorage';
import CustomButton from '../button';
import SelectCurrency from '../selectCurrency';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
// import ShowCurrencies from '../../../utils/showCurrencies';
import StepsBanner from '../../../images/ramadan/zakat_banner_for_the_Ramadan_Page.jpg';

function IftarDonationWidget(props) {
  const {
    topList = [],
    bottomList = [],
    cardTitle = '',
    cardSubTitle = '',
    topCardTitle = '',
    topCardImageBlue = '',
    topCardProduct = '',
    topBanner = '',
    buttonLable = 'Donate Now',
  } = props;
  const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY)) || {};
  const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [topListState, setTopListState] = useState([]);
  const [columns, setColumns] = useState([]);
  const [focusedCardId, setFocusedCardId] = useState(null);
  const [openDescriptionId, setOpenDescriptionId] = useState(null);

  const navigate = useNavigate();
  const [totalAmount, setTotalAmount] = useState(0);

  // State for quantities (for items in topList and bottomList)
  const [quantities, setQuantities] = useState(() => {
    const initialQuantities = {};
    [...topList, ...bottomList].forEach(item => {
      initialQuantities[item.creator] = { quantity: 0, selected: false };
    });
    return initialQuantities;
  });

  const handleSelectCard = itemId => {
    setFocusedCardId(itemId);
  };

  const handleBlur = e => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setFocusedCardId(null);
    }
  };

  const handleAddQty = (prd, index) => {
    let tempPrd = { ...prd, quantity: 1 };
    addProduct({ product: tempPrd, currency: selectedCurrencyStore, justAddToLocalStorage: true });

    setQuantities(prev => {
      const updatedQuantities = {
        ...prev,
        [prd.creator]: {
          ...prev[prd.creator],
          quantity: (prev[prd.creator]?.quantity || 0) + 1,
          selected: true,
        },
      };

      return updatedQuantities;
    });
  };

  const handleReduceQty = (prd, index) => {
    const currentQty = quantities[prd.creator]?.quantity || 0;
  
    if (currentQty <= 0) return;
  
    if (currentQty > 1) {
      let tempPrd = { ...prd, quantity: -1 };
      editProduct({ product: tempPrd, currency: selectedCurrencyStore, justAddToLocalStorage: true });
  
      setQuantities(prev => ({
        ...prev,
        [prd.creator]: {
          ...prev[prd.creator],
          quantity: currentQty - 1,
          selected: true,
        },
      }));
    } else {
      // When reducing from 1 to 0
      let tempPrd = { ...prd, quantity: -1 };
      editProduct({ product: tempPrd, currency: selectedCurrencyStore, justAddToLocalStorage: true });
  
      setQuantities(prev => ({
        ...prev,
        [prd.creator]: {
          ...prev[prd.creator],
          quantity: 0,
          selected: false,
        },
      }));
    }
  };

  const handleRemoveProduct = prd => {
    removeProduct(prd);
    handleSelectedGiving();
  };

  const handleSelectedGiving = () => {
    let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    let temp = [];

    topListState.map((item, index) => {
      basketStatesFromLocalStorage &&
        basketStatesFromLocalStorage.products.map(innerItem => {
          if (innerItem.product && innerItem.product.creator === item.creator) {
            item = Object.assign({}, item, {
              isAdded: true,
              quantity: innerItem.quantity,
            });
          }
          return innerItem;
        });
      temp[index] = item;
      return item;
    });

    setTopListState(temp);
  };

  const handleCheckboxChange = prd => {
    setQuantities(prevQuantities => {
      const newQuantities = { ...prevQuantities };
      const isSelected = !newQuantities[prd.creator]?.selected;

      if (isSelected) {
        let tempPrd = { ...prd, quantity: 1 };
        addProduct({ product: tempPrd, currency: selectedCurrencyStore, justAddToLocalStorage: true });

        newQuantities[prd.creator] = {
          quantity: 1,
          selected: true,
        };
      } else {
        handleRemoveProduct(prd);
        newQuantities[prd.creator] = {
          quantity: 0,
          selected: false,
        };
      }

      return newQuantities;
    });
  };

  const toggleDescription = id => {
    setOpenDescriptionId(openDescriptionId === id ? null : id);
  };

  const handleDonateNow = () => {
    navigate('/checkout');
  };

  const currAmmount = (currencyCode, amount) => {
    return `${getSymbolFromCurrency(currencyCode.toLowerCase())}${amount}`;
  };

  useEffect(() => {
    setTopListState(topList);
  }, [topList, selectedCurrencyStore]);

  useEffect(() => {
    const half = Math.ceil(topListState.length / 2);
    setColumns([topListState.slice(0, half), topListState.slice(half)]);
  }, [topListState]);

  useEffect(() => {
    const grandTotal = basketStatesFromLocalStorage.grandTotal;
    setTotalAmount(grandTotal ?? 0);
  }, [handleCheckboxChange, handleSelectedGiving]);

  useEffect(() => {
    setQuantities(prevQuantities => {
      const updatedQuantities = { ...prevQuantities };
      const productsFromLocalStorage = basketStatesFromLocalStorage.products || [];
  
      const localStorageProductCreators = new Set(
        productsFromLocalStorage.map(item => item?.product?.creator)
      );
  
      // Update or add products from localStorage
      productsFromLocalStorage.forEach(item => {
        const productCreator = item?.product?.creator;
        if (productCreator) {
          updatedQuantities[productCreator] = {
            quantity: item.quantity,
            selected: true,
          };
        }
      });
  
      // Set quantity to 0 for products not in localStorage
      Object.keys(updatedQuantities).forEach(creator => {
        if (!localStorageProductCreators.has(creator) && updatedQuantities[creator].quantity > 0) {
          updatedQuantities[creator] = {
            ...updatedQuantities[creator],
            quantity: 0,
          };
        }
      });
  
      return updatedQuantities;
    });
  }, [basketStatesFromLocalStorage]);
  

  return (
    <div className="bg-white flex flex-col items-center justify-center md:p-4" tabIndex={-1} onBlur={handleBlur}>
      <div className="bg-transparent shadow-lg p-2 md:p-4 w-full md:w-[648px] min-h-fit max-w-full mx-auto ">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-primaryDark mb-4">{cardTitle}</h2>
          <div dangerouslySetInnerHTML={{ __html: cardSubTitle }} />
          <a
            href={topBanner?.link || "/zakat"}
            target=""
            rel="noopener noreferrer"
            className=" w-full h-[84px]- bg-[#CDF1FF] mb-4 flex justify-center items-center relative rounded-lg shadow-lg cursor-pointer overflow-hidden"
          >
            <div className="absolute inset-0 bg-no-repeat bg-center bg-cover opacity-30- rounded-lg"></div>
            <div className="relative z-10 flex items-center">
              <img className="w-full" src={topBanner?.image || StepsBanner} alt={topBanner.alt || "Zakat"} />
            </div>
          </a>
          {topCardProduct.map(item => {
            const quantityValue = quantities[item.creator]?.quantity || 0;
            const price = item[selectedCurrencyStore.toLowerCase()] || item.usd || 0;

            return (
              <div
                key={item.id}
                className={`border-2 border-pink rounded-lg h-[84px] justify-between flex items-center px-1 shadow-md ${'bg-white'}`}
                onClick={e => {
                  e.stopPropagation();
                  handleSelectCard(item.id);
                }}
                tabIndex={0}
                onBlur={handleBlur}
              >
                <div className="flex items-center w-full justify-between">
                  <div className="flex ml-1 ">
                    <h3 className={`font-brandingBold text-[16px] sm:text-[16px] ${'text-primaryDark'} leading-[12px] text-left decoration-[1px]`}>
                      {topCardTitle}
                    </h3>
                  </div>
                  <div className="flex items-center">
                    <div className="flex items-center flex-col">
                      <img className="w-[80px] h-[37px] rounded" src={topCardImageBlue} alt="Orphans_Food" />
                      <p className={`font-brandingBold text-[13px] ${'text-primaryDark'} leading-[12px] text-center decoration-[1px]`}>{item.name}</p>
                    </div>
                    <div className="flex items-center justify-center space-x-0.5 whitespace-nowrap">
                      <p className={`font-brandingBold text-[16px] ${'text-primaryDark'} leading-[12px] text-center decoration-[1px]`}>
                        {'= '}
                        {currAmmount(selectedCurrencyStore, price)}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-end ml-2">
                    {quantityValue === 0 ? (
                      <button className="bg-pink text-white mr-3 px-1.5 py-1.5 rounded-lg flex items-center justify-center font-bold" onClick={() => handleAddQty(item)}>
                        <svg className="w-4 h-4 mx-auto font-bold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" /></svg>
                      </button>
                    ) : (
                      <div className="flex items-center">
                        <button className="text-pink text-[22px] md:text-[26px] font-bold px-2" onClick={() => handleReduceQty(item)}>
                          -
                        </button>
                        <span className="bg-pink border-pink text-white text-[12px] md:text-[14px] border rounded-lg px-2 py-0.5">
                          {quantityValue}
                        </span>
                        <button className="text-pink text-[22px] md:text-[26px] font-bold px-2" onClick={() => handleAddQty(item)}>
                          +
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
            {topListState.length === 0 ? (
              <div className="flex justify-center items-center w-[300px] h-[200px]">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-[#253B7E]"></div>
              </div>
            ) : (
              columns.map((column, colIndex) => (
                <div key={colIndex} className="space-y-3">
                  {column.map(item => {
                    const quantityValue = quantities[item.creator]?.quantity || 0;
                    const price = item[selectedCurrencyStore.toLowerCase()] || item.usd || 0;

                    return (
                      <div
                        key={item.id}
                        className={`border ${openDescriptionId === item.id ? '' : 'h-[84px]'} rounded-lg  flex items-center p-1 shadow-md`}
                        onClick={e => {
                          e.stopPropagation();
                          handleSelectCard(item.id);
                        }}
                        tabIndex={0}
                        onBlur={handleBlur}
                      >
                        <div className="flex flex-col items-center  w-full ">
                          <div className="flex items-center w-full">
                            <div>
                              <div className="flex items-center ml-1">
                                <img src={item.image_link} alt={item.name} className="w-16 h-16 object-cover mr-2 rounded shadow-md" />
                                <h3 className="font-brandingBold text-left text-[16px] sm:text-[14px] leading-[1.2] md:leading-[1.2] text-primaryDark ">{item.name}</h3>
                                <button
                                  onClick={e => {
                                    e.stopPropagation();
                                    toggleDescription(item.id);
                                  }}
                                  className="focus:outline-none"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={`h-5 w-5 transform transition-transform ${openDescriptionId === item.id ? 'rotate-180' : ''}`}
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            <div className="flex items-center justify-end ml-auto">
                              <div className="flex flex-col items-center ml-2">
                                <p className="font-brandingBold text-[16px] sm:text-[12px] text-pink border-pink rounded-md border px-1">
                                  {currAmmount(selectedCurrencyStore, Number(price).toLocaleString('en-US', { minimumFractionDigits: 0 }))}
                                </p>
                              </div>
                              {
                                <div className="flex items-center">
                                  <button
                                    className="text-pink text-[25px] font-bold px-2"
                                    onClick={e => {
                                      e.stopPropagation();
                                      handleReduceQty(item, colIndex);
                                    }}
                                  >
                                    -
                                  </button>
                                  <span className="border border-pink bg-pink text-white text-[14px] rounded-lg px-2 py-0.5">{quantityValue}</span>
                                  <button
                                    className="text-pink text-[25px] font-bold px-2"
                                    onClick={e => {
                                      e.stopPropagation();
                                      handleAddQty(item, colIndex);
                                    }}
                                  >
                                    +
                                  </button>
                                </div>
                              }
                            </div>
                          </div>
                          {openDescriptionId === item.id && (
                            <div className="sm:text-[12px] text-[16px] p-2 text-[#253B7E] font-branding ">
                              {item.short_description || item.description}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))
            )}
          </div>
          <div className="flex flex-col sm:flex-row py-7">
            <div className="w-full sm:w-[70%]">
              {bottomList &&
                bottomList.map(item => {
                  const quantityValue = quantities[item.creator]?.quantity || 0;
                  const price = item[selectedCurrencyStore.toLowerCase()] || item.usd || 0;

                  return (
                    <div
                      key={item.id}
                      className={`flex w-full md:whitespace-nowrap items-center rounded-md mt-1 ${
                        quantityValue > 0 ? 'border w-fit border-pink' : ''
                      }`}
                    >
                      <div className="flex ml-1">
                        <h3 className="font-branding text-[14px] font-medium text-left decoration-[1px] text-textPrimary">{item.name}</h3>
                      </div>
                      <div className="flex-1 text-right ml-1">
                        <p className="font-branding text-[14px] decoration-[1px] font-bold text-pink">
                          {currAmmount(selectedCurrencyStore, price)} {selectedCurrencyStore.toUpperCase()}
                        </p>
                      </div>
                      <div className="flex items-center justify-center ml-1">
                        <div className="flex items-center">
                          <button className="text-[#797979] text-[14px] font-bold text-lg px-2" onClick={() => handleReduceQty(item)}>
                            -
                          </button>
                          <span className="text-[10px] text-[#797979] border border-[#797979] rounded-md px-1 py-0.5">{quantityValue}</span>
                          <button className="text-[#797979] text-[14px] font-bold text-lg px-2" onClick={() => handleAddQty(item)}>
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="w-full sm:w-[30%] flex justify-end mt-4 sm:mt-0">
              <div className="w-full h-[90px] sm:w-[112px] sm:h-[79px] rounded-lg bg-[#F6036212] bg-opacity-30 flex flex-col items-center justify-center">
                <p className="text-textPrimary font-bold text-[14px]">Total Amount</p>
                <p className="text-pink text-sm mt-1 text-[18px] font-bold">
                  {currAmmount(selectedCurrencyStore, Number(totalAmount).toLocaleString('en-US', { minimumFractionDigits: 0 }))}{' '}
                  {selectedCurrencyStore.toUpperCase()}
                </p>
              </div>
            </div>
          </div>

          <div className="flex ">
            <div className="w-[30%] z-[31]">
              <SelectCurrency onChangeCurrency={e => {}} classNamePrefix="home_select" />
            </div>
            <div className="w-[70%] flex justify-end">
              <CustomButton
                title={'Donate Now'}
                onClick={handleDonateNow}
                className="bg-pink w-[90%] text-white rounded-full hover:bg-primaryDark text-xs w-[30px] h-[20px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IftarDonationWidget;
