// ------------------------------ Builder Imports & Dependencies ------------------------------
import { Builder } from '@builder.io/react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// ------------------------------ Selectors & Hooks ------------------------------
import { selectSelectedCurrency } from "../../../../../store/user";
import { selectProducts } from "../../../../../store/products";
import useHandleAddProduct from "../../../../../utils/handleAddProduct";
import useCart from "../../../../../hooks/cart/useCart";

// ------------------------------ Utilities & Components ------------------------------
import ReactSelect from 'react-select';
import SelectCurrency from "../../../../general/selectCurrency";
import ShowCurrencies from "../../../../../utils/showCurrencies";
import { openSheet } from '../../../../../store/sheetToggler';
import CustomButton from '../../../../general/button';
import creditImg from '../../../../../images/payment/credit-cards.png';

// ------------------------------ Select Styles ------------------------------
// Custom scroll styling for react-select dropdown
const styles = {
    menuList: base => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '6px',
        height: '0px'
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888'
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#555'
      }
    })
  };

// ------------------------------ Static Dropdown Options ------------------------------


// Recurring donation modes shown in recurring select dropdown
const WidgetHomeHorizontal = ({
    defaultPrice,
    defaultProduct = {
      creator: '', label: '', value: null
    },
    dropDownProducts,
    mode = 'one-off',
    addToCart = true,
    isSquared = false,
    suggestedPrices = '100,150,200,500',
    containerClassNames = ''
  }) => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector((state) => selectProducts(state));
    const [productsList, setProductsList] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [addProduct] = useHandleAddProduct();
    const [priceOptions, setPriceOptions] = useState([100, 150, 200, 500]);
    const [currentCurrency, setCurrentCurrency] = useState();
    const [selectedProduct, setSelectedProduct] = useState({
      creator: defaultProduct.creator,
      label: defaultProduct.label,
      value: defaultProduct.value,
    });
    const [selectedPrice, setSelectedPrice] = useState(defaultPrice || 200);
    const [productAmount, setProductAmount] = useState(selectedPrice);
    const [currentProduct, setCurrentProduct] = useState({
      creator: defaultProduct.creator,
      label: defaultProduct.label,
      value: defaultProduct.value,
    });
  
    const [selectedRecurring,setSelectedRecurring] = useState({
      value : mode || 'one-off'
    })
    /* -------------------------------------------------------------------------- */
    const dispatch = useDispatch();
    const { handleAddToGiving: handleAddToGivingCart } = useCart();
    /* ------------------------------ Start methods ----------------------------- */
    const handleProduct = (value) => {
      allProducts.map(
        (item) => item.id === value.value && setSelectedProduct(item),
      );
      setCurrentProduct(value);
    };
    const handleAddNewProduct = () => {
      let tempProduct = selectedProduct;
      let customAmount = Number(productAmount)
        ? Number(productAmount)
        : Number(selectedPrice)
          ? Number(selectedPrice)
          : defaultPrice[0] || 200;
      tempProduct = Object.assign({}, tempProduct, {
        quantity: customAmount,
        recurring: selectedRecurring.value,
      });
  
      if (addToCart) {
        handleAddToGivingCart(tempProduct, "custom");
        dispatch(openSheet());
      } else {
        addProduct({ product: tempProduct, currency: currentCurrency });
      }
    };

    const dropDownList = useMemo(() => {
      return Array.isArray(dropDownProducts)
        ? dropDownProducts
        : typeof dropDownProducts === 'string'
          ? dropDownProducts.split(/\s*,\s*/).filter(Boolean)
          : [];
    }, [dropDownProducts]); 
    
    /* ------------------------------- Start hooks ------------------------------ */
    const handleAmountKeyDown = (e) => {
      if (['e', 'E', '+', '-', '.'].includes(e.key)) {
        e.preventDefault();
      }
    };
    
    const handleAmountChange = (e) => {
      const regex = /^$|^[1-9][0-9]*$/; 
      if (regex.test(e.target.value)) {
        setProductAmount(e.target.value);
        setSelectedPrice(null);
      }
    };
    
    /* ------------------------------- Start hooks ------------------------------ */
    const getAllProducts = (products) => {
      setIsLoaded(true);
      let tempMakeDonationProducts = [];
      let tempProductsList = [];
      let tempImportantProducts = [];

      products.forEach((item) => {
        item.sub_categories.map((inner_item) => {
          inner_item.products.map((inner_item_product) => {
            if (Number(inner_item_product.status) === 1) {
              if (inner_item_product.creator === defaultProduct.creator) {
                setSelectedProduct(inner_item_product);
              }
              if (dropDownList.includes(inner_item_product.creator)) {
                console.log(inner_item_product.creator);
                tempMakeDonationProducts.push(inner_item_product);
                tempProductsList.push({
                  value: inner_item_product.id,
                  label: inner_item_product.name,
                  creator: inner_item_product.creator,
                });
              }
            }
            return inner_item_product;
          });
          return inner_item;
        });
        return item;
      });
      
      tempImportantProducts = tempImportantProducts.concat(tempProductsList);
      // Ensure no empty values
      tempImportantProducts = tempImportantProducts.filter((n) => n);
      // Sort so dropDownProducts come first
      tempImportantProducts.sort((a, b) => {
        const aIsDropDown = dropDownProducts.includes(a.creator);
        const bIsDropDown = dropDownProducts.includes(b.creator);
        
        if (aIsDropDown && !bIsDropDown) return -1;
        if (!aIsDropDown && bIsDropDown) return 1;
        return 0;
      });
  
      // Check if default product exists in the list
      const hasDefaultProduct = tempImportantProducts.some(product => product.creator === defaultProduct.creator);
  
      if (!hasDefaultProduct) {
        tempImportantProducts.unshift({
          value: defaultProduct.value,
          label: defaultProduct.label,
          creator: defaultProduct.creator,
        });
      }
  
      const defaultProductIndex = tempImportantProducts.findIndex(product => product.creator === defaultProduct.creator);
      if (defaultProductIndex !== -1) {
        const [defaultProductCreator] = tempImportantProducts.splice(defaultProductIndex, 1); 
        tempImportantProducts.splice(0, 0, defaultProductCreator); 
      }
  
      setProductsList(tempImportantProducts);
      setAllProducts(tempMakeDonationProducts);
      try {
      } catch (error) {
        console.error("Error fetching selected products:", error);
      }
    };
    
    useEffect(() => {
      products && Object.keys(products).length && getAllProducts(products);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);
    useEffect(() => {
      selectedCurrencyStore
        ? setCurrentCurrency(selectedCurrencyStore)
        : setCurrentCurrency("AUD");
    }, [selectedCurrencyStore]);
    useEffect(() => {
      // Parse suggested prices from props
      if (suggestedPrices) {
          try {
              const parsedPrices = suggestedPrices
                  .split(',')
                  .map(price => parseInt(price.trim(), 10))
                  .filter(price => !isNaN(price) && price > 0);
              
              if (parsedPrices.length > 0) {
                  setPriceOptions(parsedPrices);
                  // Update selected price if it's not in the new options
                  if (!parsedPrices.includes(selectedPrice)) {
                      setSelectedPrice(parsedPrices[0]);
                  }
              }
          } catch (e) {
              // Fallback to default prices
              setPriceOptions([100, 150, 200, 500]);
          }
      }
  }, [suggestedPrices]);

  return (
    <section className={`w-full bg-[#00a3da] px-3 md:px-6 py-3 md:py-6 flex items-center md:top-[64px] lg:top-[96px] w-full z-30 ${containerClassNames}`}>
      <div className="w-full md:container md:mx-auto">
        <div className={`grid grid-cols-12 lg:grid-cols-[1.5fr_1fr_1.5fr_1fr_1fr] gap-2 lg:gap-2 md:contaier mx-auto lg:px-2`}>
          <div className={`col-span-12 md:col-span-5 lg:col-span-1 order-3 md:order-1 flex items-center bg-white rounded-md h-[38px] ${isSquared && 'usa-select'}`}>
            <div className="min-w-max">
              <SelectCurrency onChangeCurrency={e => setCurrentCurrency(e.value)} classNamePrefix="home_select" />
            </div>
            <input
                value={productAmount}
                className={`h-[38px] text-[#777] w-full truncate p-2 text-sm pl-1 md:text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent ${!isSquared && 'rounded-lg'}`}
                type="number"
                placeholder="Amount"
                min="0"
                onKeyDown={handleAmountKeyDown}
                onChange={handleAmountChange}
              />
          </div>

          <div className="col-span-12 lg:col-span-1 order-2 md:order-3 lg:order-2 grid grid-cols-12 lg:flex gap-2">
            {priceOptions.map((price, index) => {
                return (
                  <div key={`prices${index}`} className="col-span-3">
                    <div
                      className={`h-[38px] text-sm transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 py-2 md:py-4 min-w-[60px] ${!isSquared && 'rounded-md'} ${
                selectedPrice == price ? 'bg-[#F60362] !text-white' : 'bg-white text-[#F60362]'
              }`}
                      onClick={() => {
                        setSelectedPrice(price);
                        setProductAmount(price);
                      }}
                    >
                      {ShowCurrencies(currentCurrency, price, false)}
                    </div>
                  </div>
                );
              })}
          </div>

          <div className={`col-span-12 md:col-span-7 lg:!col-span-1 order-1 md:order-2 lg:order-3 ${isSquared && 'usa-select'}`}>
            <ReactSelect
                options={productsList}
                className="max-h-[48px] [&>div]:!rounded-md"
                value={currentProduct}
                defaultValue={defaultProduct}
                isSearchable={false}
                styles={styles}
                placeholder={"Select Product"}
                onChange={e => handleProduct(e)}
                classNamePrefix="home-select" 
              />
          </div>

          <div className={`col-span-6 lg:col-span-1 order-4 px-3 h-[38px] bg-[#80ceff] flex items-center justify-center ${!isSquared && 'rounded-md'}`}>
              <img
                src={creditImg}
                alt="MATW"
                className="w-full h-auto w-[260px]- h-[20px]- sm:w-[254px]- sm:h-[28px]- md:w-[221px]- md:h-[25px]- lg:w-[125px]- lg:h-[14px]- xl:w-full- xl:h-auto-"
              />
          </div>
          <div className="order-5 col-span-6 lg:col-span-1">
            <CustomButton
              title={'Quick Donate'}
              disabled={!isLoaded}
              className={`w-full hover:!bg-[#F60362] !h-[38px] !px-2 rounded-md ${isLoaded ? 'blob red' : ''} ${isSquared && '!rounded-none'}`}
              onClick={handleAddNewProduct} />
          </div>
          </div>
        </div>
    </section>
  )
}

Builder.registerComponent(WidgetHomeHorizontal, {
  name: 'WidgetHomeHorizontal',
  inputs: [
    {
      name: "defaultProduct",
      type: "object",
      defaultValue: {
        creator: "",
        label: "",
        value: null,
      },
      friendlyName: "Default Product Info",
      subFields: [
        {
          name: 'creator',
          type: 'string',
          helperText: 'Enter product creator ID',
          required: true,
          hideFromUI: false
        },
        {
          name: 'label',
          type: 'string',
          helperText: 'Display name for the product',
          required: true,
          hideFromUI: false,
          friendlyName: "Product Label"
        },
        {
          name: 'value',
          type: 'number',
          helperText: 'Product reference value (usually product ID)',
          required: true,
          hideFromUI: false,
          friendlyName: "Product Value ID"
        },
      ]
    },
    {
      name: 'dropDownProducts',
      type: 'string',
      required: true,
      helperText: 'Enter product creator codes(comma seperated)',
      friendlyName: "Products Dropdown"
    },
    {
      name: "defaultPrice",
      type: "number",
      defaultValue: 200,
      friendlyName: "Default Donation Amount"
    },
    {
      name: 'suggestedPrices',
      type: 'string',
      helperText: 'Comma-separated list of suggested prices (e.g., "100,150,200,500")',
  },
    {
      name: "containerClassNames",
      type: "string",
      defaultValue: "",
      friendlyName: "Main Container Tailwind Classes"
    },
    {
      name: "addToCart",
      type: "boolean",
      defaultValue: true,
      friendlyName: "Enable Side Cart Add"
    },
    {
      name: "isSquared",
      type: "boolean",
      defaultValue: false,
      friendlyName: "Sharp Widget Edges"
    },
    {
      name: 'mode',
      type: 'enum',
      friendlyName: 'Donation Mode',
      helperText: 'Select how often the donation should recur.',
      enum: ['one-off', 'week', 'month'],
      enumLabels: ['Single', 'Weekly', 'Monthly'],
      defaultValue: 'one-off',
    }
  ],
});

export default WidgetHomeHorizontal